<template>
  <v-row no-gutters>
    <v-col class="event-slot">
      <v-sheet>
        <v-calendar
            ref="calendar"
            :first-interval="firstTimeOfTheDay"
            :interval-minutes="60"
            :interval-count="durationOfTheDay"
            :now="eventDate"
            :value="eventDate"
            :events="events"
            :event-overlap-threshold="30"
            @click:event="goToEventLink"
            :event-color="getEventColor"
            :interval-format="intervalFormat"
            type="day">
            <template v-slot:event="{ event }">
                <div class="px-2">
                <strong>{{ event.name }}</strong>
                {{ getTimeOnly(event.start) }} - {{ getTimeOnly(event.end) }}
                </div>
            </template>
            </v-calendar>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import FormatMixin from "@/mixins/format.mixin";
export default {
    name: 'EventSlotCalendar.vue',
    props: 
    {
        events: Array,
        eventDate: String,
    },
    mixins: [FormatMixin],
    data: () => ({
        defaultFirstTimeOfTheDay: 7,
        defaultDurationOfTheDay: 11,
    }),
    mounted () {
        this.$refs.calendar.scrollToTime('08:00')
    },
    computed: {
        firstTimeOfTheDay() {
            if(this.events == undefined || this.events.length == 0) return this.defaultFirstTimeOfTheDay;

            let firstEventTime = this.getTimeOnly(this.events[0].start).split(':')[0];
            return Number(firstEventTime) - 1;
        },
        durationOfTheDay() {
            if(this.events == undefined || this.events.length == 0) return this.defaultDurationOfTheDay;

            const lastIndex = this.events.length - 1;
            let lastEventTime = this.getTimeOnly(this.events[lastIndex].end).split(':')[0];
            return Number(lastEventTime) - this.firstTimeOfTheDay + 1;
        }
    },
    methods:
    {
        intervalFormat(interval) {
            return interval.time
        },
        editTitle()
        {
            this.editText = !this.editText;
            this.$nextTick(() => {
                const title = this.$refs.title;

                if (title)
                {
                    title.focus();
                }
                
            })

            if (!this.editText)
            {
                this.$emit('updated', this.text);
            }
        },
        getEventColor (event) {
            return event.link ? 'primary' : 'primary darken-3';
        },
        goToEventLink({event})
        {
            if (event.link)
            {
                window.open(event.link, '_blank');
            }
            
        }
        
    }
}
</script>
<style>
.event-slot .v-calendar-daily__head
{
    display: none;
}

.event-slot .theme--dark.v-calendar-daily {
    background-color: #181717;
}

.event-slot .theme--dark.v-calendar-daily,
.event-slot .theme--dark.v-calendar-daily .v-calendar-daily__day,
.event-slot .theme--dark.v-calendar-daily .v-calendar-daily__day-interval,
.event-slot .theme--dark.v-calendar-daily .v-calendar-daily__intervals-body .v-calendar-daily__interval-text,
.event-slot .theme--dark.v-calendar-daily .v-calendar-daily__interval::after,
.event-slot .theme--dark.v-calendar-daily .v-calendar-daily__intervals-body
{
    border: none !important;
}

.event-slot .theme--dark.v-calendar-events .v-event-timed {
    border-left: 2px solid white !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: 5px solid #181717 !important;
    font-size: medium;
}

.event-slot .v-calendar-daily__interval-text
{
    font-size: 13px;
}
</style>