import { mapState } from 'vuex';
export default {
    computed:
    {
        ...mapState('menuHelper', {
            fuelTypes: state => state.fuelTypes,
            foodRestrictions: state => state.foodRestrictions,
            productStatuses: state => state.productStatuses,
            suppliers: state => state.suppliers,
            roadtripLabels: state => state.roadtripLabels,
            vehicleMakes: state => state.vehicleMakes,
            countries: state => state.countries,
            productCategories: state => state.productCategories,
            accountOrigins: state => state.accountOrigins,
            roadtripLocationTypes: state => state.roadtripLocationTypes
        }),
    },
    methods: 
    {
        getFuelType(id)
        {
            if(id == undefined) return;

            let fuel = this.fuelTypes.find(x => x.item1 == id);
            return fuel ? fuel.item2.replace('_', ' ') : '';
        },
        getFoodRestrictions(allergies){
            let allergyInfo =  allergies.map(v => this.foodRestrictions.find(o => o.item1 === v).item2.toLowerCase()).join(', ');
            return allergyInfo == '' ? '-' : allergyInfo;
        },
        getFoodRestriction(id){
            if(id == undefined) return;

            let allergy = this.foodRestrictions.find(x => x.item1 == id);
            return allergy ? allergy.item2 : '';
        },
        getProductStatusId(status)
        {
            return this.productStatuses.find(x => x.item2 === status).item1;
        },
        getProductCategoryId(category)
        {
            return this.productCategories.find(x => x.item2 === category).item1;
        },
        getRoadtripLabelId(label)
        {
            return this.roadtripLabels.find(x => x.item2.toLowerCase() === label.toLowerCase()).item1;
        },
        getRoadtripLabel(id)
        {
            let label = this.roadtripLabels.find(x => x.item1 == id);
            return label ? label.item2 : '';
        },
        getSupplierName(id)
        {
            if(id == undefined) return;

            let supplier = this.suppliers.find(x => x.item1 == id);
            return supplier ? supplier.item2 : '';
        },
        getLocationTypeName(id)
        {
            if(id == undefined) return;

            let roadtripLocationType = this.roadtripLocationTypes.find(x => x.item1 == id);
            return roadtripLocationType ? roadtripLocationType.item2 : '';
        },
        getVehicleMake(id)
        {
            let make = this.vehicleMakes.find(x => x.item1 == id);
            return make ? make.item2 : '';
        },
        getCountryName(id)
        {
            let country = this.countries.find(x => x.item1 == id);
            return country ? country.item2 : '';
        },
        getAccountOriginId(name)
        {
            return this.accountOrigins.find(x => x.item2 === name).item1;
        },
        isElectricCar(fuelTypeId)
        {
            return fuelTypeId == 2;
        }
    }
}