import FormatMixin from "@/mixins/format.mixin";
export default {
    mixins: [FormatMixin],
    data: function () {
        return {
            numbers: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
            rules: {
                required: value => !!value || 'Veld is verplicht.',
                requiredExcept(value, isAccepted)
                {
                    // If value is not empty or value is equal to accepted value, return true
                    // Else, return error.
                    if (!!value || isAccepted) return true;
                    return 'Veld is verplicht.';
                },
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
                date: value => {
                    if(value == null || value == "") return true;
                    const pattern = /^(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-](19|20)\d\d$/
                    return pattern.test(value) || 'DD-MM-YYYY format'
                },
                birthdayInRange: value => {
                    if (value == null || value == "") return true;
                    return this.getBirthdayValidation(value, 0);
                },
                birthdayOfAccountOwnerInRange: value => {
                    if (value == null || value == "") return true;
                    return this.getBirthdayValidation(value, this.account_age_limit);
                },
                password: value => !!value || 'Wachtwoord is verplicht',

                // name validations
                nameLength: value => (value || '' ).length <= 35 || 'Uw naam is te lang.',
                validName: value => {
                    if(value == null || value == "") return true;
                    // eslint-disable-next-line no-useless-escape
                    const pattern = /^\s*([A-Za-z]([\.,] |[-']| ))+[A-Za-z]+\.?\s*|\w+$/
                    return pattern.test(value) || 'Uw naam kan alleen uit letters bestaan.'
                },

                // number validations
                validRange(value, min, max) {
                    return (value || "") >= min && (value || "") <= max || `Data geldig: [${min}-${max}]`;
                },

                timeRangeValidation(timestart, timeEnd)
                {
                    // timeStart should be less that timeEnd.
                    if (timestart < timeEnd) return true;
                    return 'De vertrektijd moet later zijn dan de aankomsttijd.';
                },

                phoneNumberLength: value => (value || '' ).length <= 20 || 'Telefoonnummer is te lang.',
                maxLength(value, max) {
                    return (value || '' ).length <= max || 'Ingevoerde data te lang.';
                },
                confirmPassword: value => value === this.password || 'De nieuwe wachtwoorden komen niet overeen.',
                agreeTerms: value => !!value || 'U dient de algemene voorwaarden en privacyverklaring te accepteren om verder te kunnen gaan.',
            }
        }
    },
    methods: {
        getBirthdayValidation(value, ageLimit)
        {
            let error = '';
            // eslint-disable-next-line no-unused-vars
            try 
            {
                let date = new Date(this.parseDate(value));
                let min = new Date(this.minBirthdate())
                let max = new Date(this.maxBirthdate(ageLimit));

                if (date < min)
                {
                    error = 'Datum is niet geldig.';
                }
                else if (date > max)
                {
                    error = ageLimit == this.account_age_limit 
                    ? 'De accounthouder dient altijd minimaal 16 jaar oud te zijn.'
                    : 'Datum ligt in de toekomst.';
                }
            }
            catch
            {
                error = 'Datum is niet geldig.';
            }

            return error == '' ? true : error;
        }
    }
}